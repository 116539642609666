/** @jsx jsx */

import { Link } from 'gatsby'
import { FC, useState } from 'react'
import { Box, jsx, Text, Link as Linker, Flex, useColorMode, Button } from 'theme-ui'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export interface HeroProps {
  data: {
    title: string
    description: string
  }
}

const Hero: FC<HeroProps> = ({ data: { title, description } }) => {
  const [colorMode] = useColorMode()

  return (
    <Box
      variant=""
      sx={{
        pt: '35vh',
        pb: 6,
        // bg: 'grays.1',
        pl: [4, 0, 0],
        pr: [4, 0, 0],
        maxWidth: ['auto', 'auto', '60ch'],
        // mx: 'auto',
      }}
    >
      <Text
        as="h1"
        sx={{
          color: 'primarytext',
          fontSize: [5, 4, 7],
          fontFamily: 'heading',
          lineHeight: 'heading',
          // textAlign: ['center', 'left', 'center'],
          textAlign: ['center', 'left', 'left'],
          fontWeight: [600, 600, 900],
          mb: 3,
          maxWidth: ['auto', 'auto', '13ch'],
        }}
      >
        We build Digital Products
      </Text>
      <Text sx={{ my: 3, color: 'white', opacity: 1, fontSize: [2, 3, 3] }}>
        <Text
          as="p"
          color="primarytext"
          sx={{
            fontSize: [2, 3, 5],
            textAlign: ['center', 'left', 'left'],
            mb: 4,
            lineHeight: 'heading',
            opacity: 0.6,
            fontWeight: 'body',
            maxWidth: ['auto', 'auto', '23ch'],
          }}
        >
          Design and Engineering talent for fast moving teams.
        </Text>
      </Text>
      <Flex sx={{ maxWidth: '60ch', mx: 'auto', mt: 4 }}>
        {/* <Button
          sx={{
            mx: ['auto', 0, 0],
            bg: 'red.9',
            color: 'grays.0',
            px: 5,
            fontWeight: 900,
            py: 3,
            fontSize: 2,
            borderRadius: '99rem',
          }}
        >
          Talk to us
        </Button> */}
        {/* <NewsLetter /> */}
        <Link
          to="/contact"
          sx={{
            fontWeight: 600,
            fontSize: [2, 2, 3],
            // textAlign: ['center', 'left', 'center'],
            bg: colorMode === 'default' ? 'primary' : 'primarytextinvert',
            color: 'white',
            // borderRadius: 5,
            // px: [3, 3, 3],
            // pt: 0,
            // pb: 2,
            py: 2,
            px: 3,
            // mb: [3, 0, 0],
            mr: 3,
          }}
        >
          Our Services
        </Link>
        <Link
          to="/case-studies"
          sx={{
            fontWeight: 600,
            fontSize: [2, 2, 3],
            // textAlign: ['center', 'left', 'center'],
            bg: 'base',
            color: 'primarytext',
            borderRadius: 3,

            border: 'solid 1px',
            borderColor: 'grays.3',
            // px: [3, 3, 3],
            // pt: 0,
            // pb: 2,
            py: 2,
            px: 3,
            // mb: [3, 0, 0],
            mr: 3,
          }}
        >
          Check Works
        </Link>
      </Flex>
    </Box>
  )
}

export default Hero
