/** @jsx jsx */
import { FC } from 'react'
import { Container, Box, Text, jsx, Flex, Grid } from 'theme-ui'

import { graphql } from 'gatsby'

import Halo from '../components/halo'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Navigation from '../components/navig'
import Plate from '../components/plate'
import Card from '../components/card'

import { WorkCard } from '../components/workcard'

import { ContestIcon, TokenIcon, TrophyIcon } from '../components/icon'
import { ServicesList, ServicesListType } from './services'

export interface NFTCardProps {
  name?: string
  match?: string
  value?: string
  coin?: string
  image?: string
  team1?: string
  team2?: string
  duration?: string
  desc?: string
  slug?: string
}

/**
 * Molecule
 */

interface WorkListItems {
  postEdges?: any
}

const WorkListItems = ({ postEdges }: WorkListItems) => {
  return (
    <Box variant="styles.bigCard" pb={7} pt={4}>
      {postEdges && (
        <Container>
          <Box pb={4}>
            <Text as="h3" mb={1} variant="text.blockTitleCentreB">
              Featured Works
            </Text>
            <Text variant="text.blockInfoCenter">Some amazing projects our team got to work with</Text>
          </Box>
          <Flex
            sx={{
              listStyle: 'none',
              // display: ['flex', 'flex', 'flex'],
              // gridGap: 3,
              // gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))',
            }}
          >
            {postEdges.map((post: any) => (
              <WorkCard post={post} />
            ))}
          </Flex>
        </Container>
      )}
    </Box>
  )
}

/**
 * Block
 * @returns
 */

interface ServiceBlockProps {
  title: string
  body: string
}

const ServiceBlock = ({ title, body }: ServiceBlockProps) => {
  return (
    <Box>
      <Box>
        {/* <Text></Blok> */}
        {/* <Image width="90px" src={designImage} mr={5} /> */}
        <Box sx={{ maxWidth: '60ch' }}>
          <Text variant="text.sectionTitle" sx={{ color: 'basealt', fontSize: 5 }}>
            {title}
          </Text>
          <Text variant="text.blockInfoCenter" sx={{ color: 'basealt', opacity: 0.7, fontSize: 2, pb: 4 }}>
            {body}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

/**
 * Block
 * @returns
 */
const Services = () => (
  <Container>
    <Box sx={{ pt: 6, pb: 6 }}>
      <Text as="h3" sx={{ fontSize: 5, color: 'basealt', fontWeight: 600 }}>
        Services we offer
      </Text>
      <Text sx={{ fontSize: 5, color: 'basealt', opacity: 0.75 }}>
        {/* variant="blockInfoCenter" */}
        We are a full-stack product development studio <br />
        All the way from idea to launch!
      </Text>
    </Box>
    <Box mt={5} sx={{ flexDirection: ['column', 'column', 'row'] }}>
      {ServicesList.map((s: ServicesListType) => (
        <ServiceBlock title={s.title} body={s.desc} />
      ))}
    </Box>
  </Container>
)

export interface HomeProps {
  data: any
}

const Home: FC<HomeProps> = ({ data }) => {
  const postEdges = (data && data.caseStudies && data.caseStudies.edges) || []

  return (
    <Box>
      <Halo />
      <Layout>
        <Navigation alt={true} invert={false} />
        <Plate variant="box.plateTint">
          <Box sx={{ position: 'relative', fontFamily: 'heading2', zIndex: 500, pb: 0, mt: 0 }}>
            <Box
              sx={{
                bg: 'base',
                minHeight: '90vh',
                height: '100%',
              }}
            >
              <Box>
                <Container>
                  <Hero data={{ title: 'Z', description: 'Y' }} />
                </Container>
              </Box>
            </Box>
          </Box>
        </Plate>
        {/* <Plate variant="box.plateTint2" sx={{ bg: 'red' }}>
          <Services />
        </Plate> */}
        {/* <Plate variant="box.plateTint3">
          <WorkListItems postEdges={postEdges} />
        </Plate> */}
      </Layout>
    </Box>
  )
}

export default Home

export const query = graphql`
  query HomePageQuery {
    caseStudies: allSanityCaseStudy(limit: 3, filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fixed(width: 400, height: 240) {
                width
                height
                src
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
