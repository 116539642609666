/** @jsx jsx */

import { FC } from 'react'
import { Box, jsx, Text } from 'theme-ui'

export interface PlateProps {
  children?: any
  variant?: string
}

const Plate: FC<PlateProps> = ({ children, variant  = 'plate1'}, props) => {
  return (
    <Box variant={variant} {...props}>
      {children}
    </Box>
  )
}

export default Plate
